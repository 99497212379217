<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">套餐订单</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-date-range 
                    ref="dateRange" 
                    label="下单时间" 
                    :minDate.sync="pageParam.params.startTimeStr" 
                    :maxDate.sync="pageParam.params.endTimeStr" />
                <!-- <jd-select-input :value.sync="searchVal1.value" :selectValue.sync="searchVal1.select" :selectOptions="selectOptions1"></jd-select-input>
                <jd-select-input :value.sync="searchVal2.value" :selectValue.sync="searchVal2.select" :selectOptions="selectOptions2"></jd-select-input> -->
                <le-input label="手机号" v-model="pageParam.params.phone" />
                <le-input label="订单编号" v-model="pageParam.params.orderSn" />
                <!-- <le-input label="站点名称" v-model="pageParam.params.stationName" /> -->
                <le-select-remote-search label="站点名称" v-model="stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
                <le-input label="站点ID" v-model="stationIdInput" />
                <le-input label="商品编号" v-model="pageParam.params.goodsId" />
                <le-input label="商品名称" v-model="pageParam.params.goodsName" />
                <le-input label="用户昵称" v-model="pageParam.params.nickName" />
                <le-select-local-search label="查看安心充" v-model="pageParam.params.anxinTag" :options="optionsYesOrNoDic" />
            </le-search-form>
            <le-pagview ref="packageOrder" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.packageOrderList">
                <el-table 
                    ref="packageOrderList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    @filter-change='filterFun'
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column prop="deviceCode" fixed="left" label="订单编号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" label="商品ID" fixed="left" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsId?row.goodsId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" label="商品名称" fixed="left" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsName?row.goodsName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="站点名称"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="用户手机"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.phone?row.phone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="实付金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠支付" min-width="120">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.discountPayAmount) }}
                                </span>
                                <span v-if="!row.orderDiscountInfoList.length">-</span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column label="退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>现金：￥{{ util.numFormat(row.refundAmount) }}</span>
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="套餐描述"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsDesc?row.goodsDesc:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="商户"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="收款商户"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.payeeCompanyName?row.payeeCompanyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="支付渠道"
                        column-key="payChannel"
                        :filter-multiple='false'
                        :filters="payChannelDic"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="支付方式"
                        column-key="payWay"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payWay | initDic(payWayDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="orderStatus | initTablelabel(orderStatusDic,'订单状态')" 
                        column-key="orderStatus"
                        :filter-multiple='false'
                        :filters="orderStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStatus | initDic(orderStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="pageParam.params.payStatus | initTablelabel(payStatusDic,'支付状态')" 
                        column-key="payStatus"
                        :filter-multiple='false'
                        :filters="payStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="退款原因" 
                        min-width="170">
                        <template slot-scope="{ row }"> 
                            <span>{{ row.refundMsg?row.refundMsg:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        min-width="120"
                        :label="pageParam.params.goodsType | initTablelabel(goodsTypeDic,'商品类型')" 
                        column-key="goodsType"
                        :filter-multiple='false'
                        :filters="goodsTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsType | initDic(goodsTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单时间" 
                        min-width="170">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTimeText?row.createTimeText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="70" fixed="right" >
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="订单详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toMoreInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="刷新订单" placement="top" v-if="scope.row.payStatus == 0 && scope.row.payChannel == 15">
                                <img src="../../assets/icon/option-history.png" class="a-wh-16" @click="refreshOrder(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="退款" placement="top" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                <img src="../../assets/icon/option-refund.png" 
                                    class="a-wh-16" 
                                    v-if=" (scope.row.payStatus == 1 || scope.row.payStatus == 2 || scope.row.payStatus == 4)" 
                                    @click="refound(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <package-order-refound ref="packageOrderRefound" @oprateFinish="refund"></package-order-refound>
    </div>
</template>

<script>
import util from '../../utils/util'
import packageOrderRefound from './child/packageOrderRefound'
import { mapState } from 'vuex';
let _this
export default {
    components:{
        packageOrderRefound
    },
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.superOrderPage,
                method: "post",
                params: {
                    companyId: '',
                    phone: '',
                    nickName: '',
                    orderSn: '',
                    goodsId: '',
                    goodsName: '',
                    stationId: '',
                    stationName: '',
                    startTimeStr: '',
                    endTimeStr: '',
                    orderStatus: [],
                    payStatus: '',
                    goodsType: '',
                    payChannel: '',
                    anxinTag: '1'
                },
                freshCtrl: 1,
            },
            stationId: '',
            stationIdInput: '',
            searchVal1: {
                value: '',
                select: '1'
            },
            selectOptions1: [{
                label: '用户手机',
                value: '1'
            },{
                label: '用户昵称',
                value: '2'
            },{
                label: '订单编号',
                value: '3'
            },{
                label: '商品编号',
                value: '4'
            },{
                label: '商品名称',
                value: '5'
            }],
            searchVal2: {
                value: '',
                select: '1'
            },
            selectOptions2: [{
                label: '站点名称',
                value: '1'
            },{
                label: '站点ID',
                value: '2'
            }],
            payChannelDic: [],//支付渠道字典
            orderStatusDic: [],//订单状态字典
            payStatusDic: [],//支付状态字典
            goodsTypeDic: [],//商品订单类型字典
            payWayDic: [],//支付方式字典
            orderStatus: '',
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 100
            },//站点数据
            optionsYesOrNoDic: []
        };
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('orderPayChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('orderStatus').then(res=>{ this.orderStatusDic = res; })
        this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
        this.$getDic('goodsType').then(res=>{ this.goodsTypeDic = res; })
        this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
        this.$getDic('yesOrNo', 'select').then(res=>{ this.optionsYesOrNoDic = res; })
    },
    activated () {
		this.pageParam.freshCtrl++;
	},
    watch: {
        // searchVal1:{
        //     deep: true,
        //     immediate:true,
        //     handler (val) {
        //         this.pageParam.params.phone = val.select==1?val.value:''
        //         this.pageParam.params.nickName = val.select==2?val.value:''
        //         this.pageParam.params.orderSn = val.select==3?val.value:''
        //         this.pageParam.params.goodsId = val.select==4?val.value:''
        //         this.pageParam.params.goodsName = val.select==5?val.value:''
        //     }     
        // },
        // searchVal2:{
        //     deep: true,
        //     immediate:true,
        //     handler (val) {
        //         this.pageParam.params.stationName = val.select==1?val.value:''
        //         this.pageParam.params.stationId = val.select==2?val.value:''
        //     }     
        // }
    },
    methods: {
        setTableData(data) {
            this.tableData = data;
        },
        handlerRest() {
            // this.searchVal1 = {
            //     value: '',
            //     select: '1'
            // }
            // this.searchVal2 = {
            //     value: '',
            //     select: '1'
            // }
            this.pageParam.params = {
                companyId: '',
                phone: '',
                nickName: '',
                orderSn: '',
                goodsId: '',
                goodsName: '',
                stationId: '',
                stationName: '',
                startTimeStr: '',
                endTimeStr: '',
                orderStatus: '',
                payStatus: '',
                goodsType: '',
                payChannel: '',
                anxinTag: '1'
            };
            this.orderStatus = ''
            this.stationId = ''
            this.stationIdInput = ''
            this.$refs['packageOrderList'].clearFilter()
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['packageOrder'].pageNum = 1
            this.pageParam.params.orderStatus = this.orderStatus?[this.orderStatus]:[]
            this.pageParam.params.stationId = this.stationIdInput || this.stationId
            this.pageParam.freshCtrl++;
        },
        // 订单退款
        refound (datas) {
            this.$refs['packageOrderRefound'].id = datas.id
            this.$refs['packageOrderRefound'].form.refundAmount = Number(((datas.payAmount - datas.refundAmount)/100).toFixed(2))
            this.$refs['packageOrderRefound'].form.returnGoods = 1
            this.$refs['packageOrderRefound'].form.payAmount = datas.payAmount
            this.$refs['packageOrderRefound'].orderSn = datas.orderSn
            try {
                this.$refs['packageOrderRefound'].form.packageType = JSON.parse(datas.goodsInfo).type
                this.$refs['packageOrderRefound'].form.packgeSoybean = JSON.parse(datas.goodsInfo).promotionPrice || 0
                this.$refs['packageOrderRefound'].form.packgeSoybeanGift = JSON.parse(datas.goodsInfo).giftAmount || 0
            } catch (error) {
                
            }
            this.$refs['packageOrderRefound'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
            }).map(item=>{
                return {
                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    discountType: item.discountType
                }
            })
            this.$refs['packageOrderRefound'].dialogVisible = true
        },
        refund () {
            this.pageParam.freshCtrl++;
        },
        //条件筛选
        filterFun(value){
            for(var key in value){
                if(key=='orderStatus'){
                    if(value[key].length==0){
                        this.orderStatus = ''
                    }else{
                        this.orderStatus = value[key][0]
                    }
                }
                if(key=='payStatus'){
                    if(value[key].length==0){
                        this.pageParam.params.payStatus = ''
                    }else{
                        this.pageParam.params.payStatus = value[key][0]
                    }
                }
                if(key=='goodsType'){
                    if(value[key].length==0){
                        this.pageParam.params.goodsType = ''
                    }else{
                        this.pageParam.params.goodsType = value[key][0]
                    }
                }
                if(key=='payChannel'){
                    if(value[key].length==0){
                        this.pageParam.params.payChannel = ''
                    }else{
                        this.pageParam.params.payChannel = value[key][0]
                    }
                }
            }
            this.handlerSearch()
            this.pageParam.freshCtrl++;
        },
        // 订单信息
        toMoreInfo (datas) {
            this.$router.push({
                path:'/order/package-order-info',
                query: {
                    id: datas?datas.id:''
                }
            })
        },
        // 管理员手动扣款
        managePay (datas) {

        },
        // 刷新订单状态
        refreshOrder (datas) {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSuperOrderStatus,
                method: "get",
                params: {
                    orderId: datas.id,
					orderType: 1,
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.$message.success('已刷新订单状态')
                    this.pageParam.freshCtrl++;
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 80px !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
